import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react'
import arrow_right from '../../../assets/arrow_right.svg'
import icon_info from '../../../assets/icon_info.svg'
import logo_electronicID from '../../../assets/logo_electronicID.svg'
import logo_iDIN from '../../../assets/logo_iDIN.svg'
import { StoreContext } from '../../../components/App'
import {
  Panel,
  PanelContent,
  PanelInfo,
  PanelList,
  PanelNavigation,
  PanelThumbnail,
} from '../EnrollCompany.styles'
import { Content, Container, ContentSection } from './enroll.styles'
import { PageHeading } from '../../../components/PageHeading/PageHeading'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'

export const IdentityVerification = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState
  const {
    enrollCompanyPage: { identityVerification: trans },
  } = store.TranslationsState.translations

  const [current, setCurrent] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  function handleCurrent(value: 'idin' | 'eidv') {
    if (!isDisabled) {
      if (value === current) {
        setCurrent('')
      } else {
        setCurrent(value)
        setIsDisabled(true)
        window.location.href = `${process.env.WEB_API_URL}/merchant-enrolment/ident-start/${value}`
      }
    }
  }

  return (
    <Container>
      <PageHeading
        title={trans.title}
        subtitle={trans.chooseIdentityVerificationMethod}
        margin="0 0 0 40px"
        marginMobile="0 0 0 80px"
      />
      <Content>
        <ContentSection>
          {isDisabled ? (
            <LoadingSpinner
              {...theme.loadingSpinner}
              height="278px"
              width="48px"
              bgColor="#fff"
              primary="#0071f3"
            />
          ) : (
            <PanelList>
              <Panel
                className={`${current === 'idin' ? 'current' : ''} ${
                  isDisabled ? 'disabled' : ''
                }`}
                onClick={() => !isDisabled && handleCurrent('idin')}
              >
                <PanelThumbnail src={logo_iDIN} alt="iDIN" />
                <PanelContent>
                  <h3>iDIN</h3>
                  <span>{trans.netherlands}</span>
                </PanelContent>
                <PanelNavigation src={arrow_right} alt="GoTo" />
              </Panel>
              <Panel
                className={`${current === 'eidv' ? 'current' : ''} ${
                  isDisabled ? 'disabled' : ''
                }`}
                onClick={() => !isDisabled && handleCurrent('eidv')}
              >
                <PanelThumbnail src={logo_electronicID} alt="ElectronicID" />
                <PanelContent>
                  <h3>
                    ElectronicID <PanelInfo src={icon_info} alt="info" />
                  </h3>
                  <span>{trans.videoIdentityVerification}</span>
                </PanelContent>
                <PanelNavigation src={arrow_right} alt="GoTo" />
              </Panel>
            </PanelList>
          )}
        </ContentSection>
      </Content>
    </Container>
  )
})
