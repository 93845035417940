import 'mobx-react-lite'
import React, { createContext, useEffect, useRef, useState } from 'react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { EnrollCompanyPage } from '../pages/EnrollCompany/EnrollCompanyPage'
import { LoginPage } from '../pages/LoginPage/LoginPage'
import { MerchantsPage } from '../pages/MerchantsPage/MerchantsPage'
import { NotFoundPage } from '../pages/NotFoundPage'

import { useResizeObserver } from 'usehooks-ts'

import { getQueryVariable } from '../methods/getQueryVariable'
import { AddressVerificationPolling } from '../pages/EnrollCompany/subpages/AddressVerificationPolling'
import { AddressVerification } from '../pages/EnrollCompany/subpages/AddressVerification'
import { BusinessAddress } from '../pages/EnrollCompany/subpages/BusinessAddress'
import { CompanyData } from '../pages/EnrollCompany/subpages/CompanyData'
import { Directors } from '../pages/EnrollCompany/subpages/Directors'
import { Ubos } from '../pages/EnrollCompany/subpages/Ubos'
import { Summary } from '../pages/EnrollCompany/subpages/Summary'
import { IdentityVerification } from '../pages/EnrollCompany/subpages/IdentityVerification'
import { IntroCompanyData } from '../pages/EnrollCompany/subpages/IntroCompanyData'
import { IntroIdentity } from '../pages/EnrollCompany/subpages/IntroIdentity'
import { Error } from '../pages/EnrollCompany/subpages/Error'
import { NotFound } from '../pages/EnrollCompany/subpages/NotFound'
import { Success } from '../pages/EnrollCompany/subpages/Success'
import { rootStore } from '../store/Root.store'
import FontStyles from '../styles/fonts.styles'
import { Toasts } from './Toast/Toast'
import { LanguagePicker } from './LanguagePicker/LanguagePicker'
import { GlobalStyle } from '../styles/global.styles'
import { CustomHelmet } from './Helmet'
import { IdentityVerificationPolling } from '../pages/EnrollCompany/subpages/IdentityVerificationPolling'

export const StoreContext = createContext(rootStore)

const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export const App: React.FC = () => {
  const [paramEnv, setParamEnv] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  const { width = 0, height = 0 } = useResizeObserver({
    ref,
    box: 'border-box',
  })

  const themeId = getQueryVariable('themeId')
  if (themeId) rootStore.AppState.changeTheme(themeId)

  useEffect(() => {
    rootStore.AppState.setPageWidth(width)
  }, [width])

  useEffect(() => {
    rootStore.AppState.setPageHeight(height)
  }, [height])

  return (
    <StoreContext.Provider value={rootStore}>
      <Router>
        <CustomHelmet />
        <FontStyles />
        <GlobalStyle
          bgColor={rootStore.AppState.theme.globals.bgColor}
          fontFamily={rootStore.AppState.theme.globals.fontFamily}
          linkColor={rootStore.AppState.theme.globals.linkColor}
          checkboxColor={rootStore.AppState.theme.globals.checkboxColor}
          checkboxColorActive={
            rootStore.AppState.theme.globals.checkboxColorActive
          }
          color={rootStore.AppState.theme.container.color}
          container={rootStore.AppState.theme.container}
        />
        <div className="App" ref={ref}>
          <Toasts />

          {window.location.pathname.includes('/enroll-company') && (
            <LanguagePicker />
          )}

          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <MerchantsPage />
                </RequireAuth>
              }
            />
            <Route
              path="/merchants"
              element={
                <RequireAuth>
                  <MerchantsPage />
                </RequireAuth>
              }
            />
            <Route path="/enroll-company" element={<EnrollCompanyPage />}>
              <Route
                path="/enroll-company/identity-intro"
                element={<IntroIdentity />}
              />
              <Route
                path="/enroll-company/identity-verification"
                element={<IdentityVerification />}
              />
              <Route
                path="/enroll-company/identity-verification-polling"
                element={<IdentityVerificationPolling />}
              />
              <Route
                path="/enroll-company/address-verification-polling"
                element={<AddressVerificationPolling />}
              />
              <Route
                path="/enroll-company/address-verification"
                element={<AddressVerification />}
              />
              <Route
                path="/enroll-company/company-intro"
                element={<IntroCompanyData />}
              />
              <Route
                path="/enroll-company/company-data"
                element={<CompanyData />}
              />
              <Route
                path="/enroll-company/business-address"
                element={<BusinessAddress />}
              />
              <Route path="/enroll-company/directors" element={<Directors />} />
              <Route path="/enroll-company/ubos" element={<Ubos />} />
              <Route path="/enroll-company/summary" element={<Summary />} />
            </Route>

            <Route
              path="/identity-verification"
              element={<EnrollCompanyPage />}
            >
              <Route
                path="/identity-verification/identity-intro"
                element={<IntroIdentity />}
              />
              <Route
                path="/identity-verification/identity-verification"
                element={<IdentityVerification />}
              />
              <Route
                path="/identity-verification/identity-verification-polling"
                element={<IdentityVerificationPolling />}
              />
              <Route
                path="/identity-verification/address-verification-polling"
                element={<AddressVerificationPolling />}
              />
              <Route
                path="/identity-verification/address-verification"
                element={<AddressVerification />}
              />
            </Route>

            {/* Error */}
            <Route path="/enroll-company/error" element={<Error />} />
            <Route path="/enroll-company/success" element={<Success />} />
            <Route path="/enroll-company/*" element={<NotFound />} />

            <Route path="/identity-verification/error" element={<Error />} />
            <Route
              path="/identity-verification/success"
              element={<Success />}
            />
            <Route path="/identity-verification/*" element={<NotFound />} />

            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </Router>
    </StoreContext.Provider>
  )
}
